import { MESSAGE_TYPES, handleMessages } from "components/Toast/functions";

export const handleGenericError = (error, t, errorMessage = null, type = MESSAGE_TYPES.error) => {
  const status = error?.response?.status || 500;
  let messageFromApi = errorMessage;

  if (error?.response && error.response?.data) {
    const { data } = error.response;
    if (data) {
      const propError = data?.errors && Object.keys(data.errors)[0];
      messageFromApi = data.detail
        || data.error
        || data.message
        || (data.errors && data.errors[0])
        || (propError && data.errors[propError][0]);
    }
  } else if (error?.message) {
    messageFromApi = error.message;
  } else if (error) {
    messageFromApi = error?.toString();
  }

  const { title, message } = handleMessages(
    type,
    status,
    t,
    messageFromApi,
  );

  return { title, message, type };
};
