import { useQuery, useMutation, useQueryClient } from "react-query";
import { getEvaluationResultsByQuery, updateEvaluationResultState } from "redux/actions/evaluationResultsActions";
import { toast } from "components/Toast/functions";
import { handleGenericError } from "common/handleGenericError";

const handleQueryError = (error, t) => {
  const { title, message, type } = handleGenericError(error, t);
  toast(type, { title, message });
};

export const useEvaluationResult = (t) => {
  const queryClient = useQueryClient();

  const fetchEvaluationResults = (filterQuery, processId, shouldFetch = true) => {
    const queryKey = ["evaluationResults", filterQuery, processId];

    const {
      data: evaluationResults = [],
      isLoading,
      isError,
      error,
      refetch,
    } = useQuery(
      queryKey,
      () => getEvaluationResultsByQuery(filterQuery, processId),
      {
        enabled: !!processId && shouldFetch,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        retry: false,
        onError: (queryError) => handleQueryError(queryError, t),
      },
    );

    return {
      evaluationResults,
      isLoading,
      isError,
      queryClient,
      error,
      refetch,
    };
  };

  const updateEvaluationResult = () => {
    const { mutateAsync: updateState } = useMutation(
      ({
        processId: processIdState,
        evaluationResultId,
        evaluationId,
        state,
      }) => updateEvaluationResultState(processIdState, evaluationResultId, evaluationId, state),
      {
        onError: (mutationError) => handleQueryError(mutationError, t),
      },
    );

    return { updateState };
  };

  return {
    fetchEvaluationResults,
    updateEvaluationResult,
  };
};
