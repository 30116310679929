import { useMutation } from "react-query";
import { updateGoalEvaluationAnswer } from "redux/actions/goalEvaluationAnswersActions";
import { toast } from "components/Toast/functions";
import { handleGenericError } from "common/handleGenericError";

const handleQueryError = (error, t) => {
  const { title, message, type } = handleGenericError(error, t);
  toast(type, { title, message });
};

export const useUpdateGoalEvaluationAnswer = (onSuccessCallback, t) => useMutation(
  ({ id, data }) => updateGoalEvaluationAnswer(id, data),
  {
    onSuccess: (data) => {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (queryError) => handleQueryError(queryError, t),

  },
);
