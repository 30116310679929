import { useQuery, useMutation, useQueryClient } from "react-query";
import { getTags, assignTags, removeTags } from "redux/actions/tagActions";
import { toast } from "components/Toast/functions";
import { handleGenericError } from "common/handleGenericError";

const handleQueryError = (error, t) => {
  const { title, message, type } = handleGenericError(error, t);
  toast(type, { title, message });
};

export const useTags = (t, resetData) => {
  const queryClient = useQueryClient();

  const {
    data: currentTags = [],
    isLoading,
    isError,
    error,
  } = useQuery("tags", getTags, {
    staleTime: Infinity,
    onError: (queryError) => handleQueryError(queryError, t),
    retry: false,
  });

  const assignTagsMutation = useMutation(
    ({ goalId, tagNames }) => assignTags({ goalId, tagNames }),
    {
      onSuccess: (updatedGoal) => {
        queryClient.invalidateQueries("tags");
        resetData(updatedGoal);
      },
      onError: (errorResponse) => {
        handleQueryError(errorResponse, t);
      },
    },
  );

  const removeTagsMutation = useMutation(
    ({ goalId, tagNames }) => removeTags({ goalId, tagNames }),
    {
      onSuccess: (updatedGoal) => {
        resetData(updatedGoal);
      },
      onError: (errorResponse) => {
        handleQueryError(errorResponse, t);
      },
    },
  );

  return {
    tags: currentTags,
    isLoading,
    isError,
    error,
    assignTagsMutation,
    removeTagsMutation,
  };
};
