import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import Select from "components/Select";
import StatisticsCard from "components/StatisticsCard";
import SimpleTable from "components/SimpleTable";
import PerformanceChart from "components/PerformanceChart";
import { getOne as getResultScaleById } from "redux/actions/resultScalesActions";
import CriticAreaTable from "components/CriticAreaTable";
import SkeletonLoader from "components/SkeletonLoader";
import {
  OBJECT_KEYS,
  DOWNLOAD_TYPE,
  MIN_VALUE,
  PAGINATION,
  SKELETONS_NUMBER,
  SKELETON_VARIANT,
} from "common/constants";
import {
  getDownloadTypes, getAverageResult,
} from "common/utils";
import { isNull, isEmpty } from "common/helpers";
import { getGoodLeaderEvolution, getSurveyProcessParticipation } from "redux/actions/surveyProcessesActions";
import { getList as getHeatMapList } from "redux/actions/performance/heatMapActions";
import {
  getList as getGoodLeaderProcesses,
  getGoodLeaderTopRanking,
  getGoodLeaderBottomRanking,
  getGoodLeaderResults,
} from "redux/actions/performance/goodLeaderActions";
import {
  StyledPaperContainer,
  StyledGridContainer,
  StyledSubtitle,
} from "styledComponents/View";
import Managers from "./components/Managers";
import {
  getTableTopsKeys,
  downloadHeatMapExcel,
  getCriticTableHeader,
  getHeatPicker,
  downloadHeatMapPDF,
  getLeaderData,
  allCards,
} from "./functions";
import { StyledGridCard, StyledSelect } from "./styles";

// TODO: remove when new design is implemented
const TalentManager = (props) => {
  const {
    values,
    filterQuery,
    selectHeatMap,
    setValues,
    prevSelectedHeatMap,
    setSelectHeatMap,
    setSearchFilter,
    searchFilter,
    tableSort,
    setPagination,
    pagination,
    handlePagination,
    setTableSort,
  } = props;
  const { t, i18n } = useTranslation("performance");
  const { language } = i18n;
  const dispatch = useDispatch();
  const history = useHistory();
  const [resultScaleId, setResultScaleId] = useState(null);

  const {
    list: goodLeaderProcesses,
    isLoadinglist: isLoadinglistGoodLeaderProcesses,
    topList: goodLeaderTopList,
    isloadingTopList: goodLeaderLoadingTopList,
    bottomList: goodLeaderBottomList,
    isLoadingBottomList: goodLeaderLoadingBottomList,
    surveyResults: goodLeaderSurveyResultList,
    loadingSurveyResults: goodLeaderSurveyResultLoadingList,
  } = useSelector(
    ({ performanceGoodLeaderReducer }) => performanceGoodLeaderReducer,
  );

  const {
    goodLeaderEvolution,
    isLoadingGoodLeaderEvolution,
    oneProcess: surveyProcess,
    isLoadingOneProcess: isLoadingSurveyProcess,
  } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );

  const { list: heatMapList, isLoadingList: isLoadingHeatMapList } = useSelector(
    ({ performanceHeatMapReducer }) => performanceHeatMapReducer,
  );

  useEffect(() => {
    if (surveyProcess) {
      setResultScaleId(surveyProcess.good_leader_process?.result_scale_id);
    }
  }, [surveyProcess]);

  const {
    one: resultScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  useEffect(() => {
    if (resultScaleId) {
      dispatch(getResultScaleById(resultScaleId));
    }
  }, [resultScaleId]);

  const getEvaluations = useCallback((chart) => {
    let evaluations = [];
    if (!isLoadinglistGoodLeaderProcesses
      && !isLoadingGoodLeaderEvolution
      && goodLeaderEvolution
      && goodLeaderProcesses
    ) {
      evaluations = goodLeaderProcesses.filter(
        (evaluation) => evaluation.surveys_answered > 0,
      ).map((item) => ({
        value: item.id,
        label: item[`name_${language}`],
      }));
    }
    const evolutionIds = new Set(goodLeaderEvolution?.map((item) => item.id) || []);

    const chartNames = evaluations
      .filter((evaluation) => evolutionIds.has(evaluation.value))
      .map((evaluation) => evaluation.label);

    return chart ? chartNames.reverse() : evaluations;
  }, [
    isLoadingGoodLeaderEvolution,
    isLoadinglistGoodLeaderProcesses,
    goodLeaderEvolution,
    goodLeaderProcesses,
    language,
  ]);

  const getInitialData = useCallback(
    (process) => {
      const EVAL = getEvaluations();
      if (!isEmpty(EVAL) && process) {
        setValues(() => ({
          evaluation: EVAL[0]?.value,
        }));
      }
    },
    [getEvaluations, setValues],
  );

  useEffect(() => {
    if (goodLeaderProcesses && !values.evaluation) {
      getInitialData(goodLeaderProcesses);
    }
  }, [dispatch, getInitialData, goodLeaderProcesses, values.evaluation]);

  useEffect(() => {
    dispatch(getGoodLeaderProcesses());
    dispatch(getGoodLeaderEvolution());
  }, [dispatch]);

  useEffect(() => {
    if (values.evaluation) {
      if (prevSelectedHeatMap !== selectHeatMap) {
        dispatch(getHeatMapList(values.evaluation, selectHeatMap, filterQuery));
      }
    }
  }, [
    dispatch,
    values.evaluation,
    selectHeatMap,
    prevSelectedHeatMap,
    filterQuery,
  ]);

  useEffect(() => {
    if (goodLeaderProcesses !== null && values.evaluation) {
      setValues((prevValues) => ({
        ...prevValues,
        goodLeaderProcess: goodLeaderProcesses[MIN_VALUE]?.id,
      }));
    }
  }, [goodLeaderProcesses, values.evaluation, setValues]);

  const handleChange = (prop, event) => {
    const processId = event.target.value;
    if (prop === "selectHeatMap") {
      setSelectHeatMap(processId);
    } else {
      dispatch(getGoodLeaderTopRanking(processId, filterQuery));
      dispatch(getGoodLeaderBottomRanking(processId, filterQuery));
      dispatch(getHeatMapList(processId, selectHeatMap, filterQuery));
      dispatch(getGoodLeaderResults(processId, false, 1, filterQuery));
      dispatch(getSurveyProcessParticipation(processId, filterQuery));
      setValues({ ...values, [prop]: processId });
    }
  };

  const handleDownloadHeatMap = (prop, event) => {
    const { value } = event.target;
    if (value !== DOWNLOAD_TYPE.none && value === DOWNLOAD_TYPE.excel) {
      downloadHeatMapExcel(getLeaderData(heatMapList), t);
    } else {
      downloadHeatMapPDF(selectHeatMap);
    }
  };

  const handleSearch = (name = "") => {
    setSearchFilter(name);
  };

  useEffect(() => {
    if (filterQuery) {
      const query = filterQuery;
      query.q.employee_person_full_name_cont = searchFilter;
      query.q.s = tableSort;
      if (
        !goodLeaderSurveyResultLoadingList
        && !isNull(goodLeaderSurveyResultList)
        && values?.evaluation
      ) {
        dispatch(
          getGoodLeaderResults(values.evaluation, false, PAGINATION.next, query),
        );
        setPagination(PAGINATION.next);
      }
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [searchFilter, tableSort]);

  const theme = useTheme();

  const isMobile = useMediaQuery(
    theme.breakpoints.down(charts.breakpoints.small),
  );

  const getCards = isLoadingSurveyProcess ? (
    <SkeletonLoader
      numberOfSkeletons={ SKELETONS_NUMBER.TWO }
      variant={ SKELETON_VARIANT.rectangular }
      height={ 150 }
      columnWidth={ isMobile ? 12 : 6 }
    />
  ) : (
    surveyProcess?.meta?.participation_by_evaluation_type
    && allCards(t, surveyProcess.meta.participation_by_evaluation_type).map((item) => (
      <Grid item xs={ 12 } sm={ 6 } key={ item.title }>
        <StatisticsCard
          title={ item.title }
          subTitle={ item.subtitle }
          participation={ item.participation }
          icon={ item.icon }
          color={ item.color }
          isFullWidth
        />
      </Grid>
    ))
  );

  return (
    <div>
      <StyledSelect container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
          <Select
            id={ OBJECT_KEYS.evaluation }
            label={ t("dashboard.filter_evaluation") }
            disabled={ isEmpty(goodLeaderProcesses) || isLoadinglistGoodLeaderProcesses }
            menuItems={ isLoadinglistGoodLeaderProcesses ? [] : getEvaluations() }
            value={ isLoadinglistGoodLeaderProcesses ? "" : values.evaluation }
            onChange={ handleChange }
            className={ "custom-select" }
          />
        </Grid>
      </StyledSelect>
      <StyledGridCard container spacing={ 2 }>
        {getCards}
      </StyledGridCard>
      <StyledPaperContainer>
        <StyledGridContainer container spacing={ 4 }>
          <Grid item xs={ 12 } sm={ 6 }>
            <SimpleTable
              title={ t("dashboard.top_collaborators") }
              header={ getTableTopsKeys(history, values.evaluation) }
              hideHeader
              data={ !isNull(goodLeaderProcesses) ? goodLeaderTopList : [] }
              isLoading={ goodLeaderProcesses === null || goodLeaderLoadingTopList }
              colSpan={ 2 }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <SimpleTable
              title={ t("dashboard.bottom_collaborators") }
              header={ getTableTopsKeys(history, values.evaluation) }
              hideHeader
              data={ goodLeaderProcesses !== null ? goodLeaderBottomList : [] }
              isLoading={ goodLeaderProcesses === null || goodLeaderLoadingBottomList }
              colSpan={ 2 }
            />
          </Grid>
        </StyledGridContainer>
        <Grid container spacing={ 4 }>
          <Grid item xs={ 12 }>
            <StyledSubtitle>{t("dashboard.graphic_title")}</StyledSubtitle>
          </Grid>
          <Grid item xs={ 12 }>
            <PerformanceChart
              title={ t("dashboard.graphic_title") }
              labels={ getEvaluations(true) }
              data={ goodLeaderEvolution ? getAverageResult(goodLeaderEvolution) : [] }
              isLoading={ isLoadingGoodLeaderEvolution }
            />
          </Grid>
        </Grid>
      </StyledPaperContainer>
      <StyledPaperContainer id={ "goodLeaderHeatMap" }>
        <Grid container spacing={ 4 }>
          <Grid item xs={ 12 } md={ 6 }>
            <StyledSubtitle>{t("dashboard.heat_map_title")}</StyledSubtitle>
          </Grid>
          <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
            <Select
              id={ "selectHeatMap" }
              label={ t("dashboard.select") }
              isDisabled={ isEmpty(heatMapList) }
              menuItems={ getHeatPicker(t) }
              value={ selectHeatMap }
              onChange={ handleChange }
              className={ "custom-select" }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
            <Select
              id={ "downloadHeatMap" }
              label={ t("dashboard.download") }
              menuItems={ getDownloadTypes(t) }
              value={ values.downloadHeatMap }
              onChange={ handleDownloadHeatMap }
              className={ "custom-select" }
              isDisabled={ isEmpty(heatMapList) }
            />
          </Grid>
        </Grid>
        <Grid item xs={ 12 }>
          <CriticAreaTable
            header={ getCriticTableHeader(t, selectHeatMap) }
            data={ getLeaderData(heatMapList) }
            isDisabled={ isEmpty(heatMapList) }
            isLoading={ isLoadingHeatMapList }
            resultScale={ resultScale }
          />
        </Grid>
      </StyledPaperContainer>
      {goodLeaderProcesses && goodLeaderProcesses[0] && (
        <StyledPaperContainer>
          <Managers
            processId={ values.evaluation }
            pagination={ pagination }
            handlePagination={ handlePagination }
            handleSearch={ handleSearch }
            query={ filterQuery }
            setTableSort={ setTableSort }
            defaultOrderName={ OBJECT_KEYS.performanceScore }
          />
        </StyledPaperContainer>
      )}
    </div>
  );
};

TalentManager.propTypes = {
  values: PropTypes.shape({
    downloadHeatMap: PropTypes.string.isRequired,
    evaluation: PropTypes.string,
  }),
  selectHeatMap: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  prevSelectedHeatMap: PropTypes.object.isRequired,
  setSelectHeatMap: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  tableSort: PropTypes.string.isRequired,
  setPagination: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  handlePagination: PropTypes.object.isRequired,
  setTableSort: PropTypes.object.isRequired,
  filterQuery: PropTypes.object,
  searchFilter: PropTypes.string,
};

export default TalentManager;
