import i18n from "i18next";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { addNotification } from "redux/actions/notifications/notificationsActions";
import {
  ERROR,
  TOAST_ERROR_DURATION,
  LOCAL_STORAGE_NAMES,
  SIGN_IN_URLS,
} from "common/constants";
import { setInLocalStorage } from "common/utils";
import { isUndefined } from "common/helpers";
import { handleGenericError } from "common/handleGenericError";

const errorHandler = (error, dispatch, typeAction, additionalData = {}, t = null) => {
  if (error?.response?.status === 401) {
    const isSignIn = SIGN_IN_URLS.find((url) => window.location.href.includes(url));
    if (isUndefined(isSignIn)) {
      setInLocalStorage(LOCAL_STORAGE_NAMES.logOutError, true);
      window.location.replace("/");
      return;
    }
  }

  const { title, message, type } = handleGenericError(error, t || i18n.t);

  dispatch(
    addNotification({
      type: ERROR,
      duration: TOAST_ERROR_DURATION,
      title,
      description: message,
    }),
  );

  toast(type || MESSAGE_TYPES.error, { title, message });

  setTimeout(() => {
    dispatch({
      ...additionalData,
      type: typeAction,
      payload: null,
    });
  }, 2000);
};

export default errorHandler;
