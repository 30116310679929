import { useQuery, useQueryClient } from "react-query";
import { getGoalsParticipation } from "redux/actions/goalsActions";
import { toast } from "components/Toast/functions";
import { handleGenericError } from "common/handleGenericError";

const handleQueryError = (error, t) => {
  const { title, message, type } = handleGenericError(error, t);
  toast(type, { title, message });
};

export const useGoalsParticipation = (t, filterQuery) => {
  const queryClient = useQueryClient();

  const {
    data: goalsParticipation, isLoading, isError, error,
  } = useQuery(
    ["goalsParticipation", filterQuery],
    () => getGoalsParticipation(filterQuery),
    {
      staleTime: Infinity,
      onError: (queryError) => handleQueryError(queryError, t),
    },
  );

  return {
    goalsParticipation,
    isLoading,
    isError,
    queryClient,
    error,
  };
};
