import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getOne as getResultScaleById } from "redux/actions/resultScalesActions";
import charts from "theme/charts";
import usePagination from "hooks/utils/usePagination";
import useHandleDispatch from "hooks/utils/useHandleDispatch";
import Select from "components/Select";
import SimpleTable from "components/SimpleTable";
import Filter from "components/Filter";
import PerformanceChart from "components/PerformanceChart";
import CriticAreaTable from "components/CriticAreaTable";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import ParticipationCard from "components/ParticipationCard";
import IndividualAccordion from "components/IndividualAccordion";
import Modal from "components/Modal";
import SkeletonLoader from "components/SkeletonLoader";
import DownloadExcelButton from "components/DownloadExcelButton";
import ModalInfo from "components/modals/ModalInfo";
import SimpleAlert from "components/SimpleAlert";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import ActionCard from "components/ActionCard";
import AlertDialog from "components/AlertDialog";
import {
  MESSAGE_TYPES,
  toast,
} from "components/Toast/functions";
import { downloadExcelFile } from "common/functions/downloadParticipation";
import { SessionContext } from "modules/session/context";
import { isEmpty, isNull } from "common/helpers";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import {
  ORG_UNITS,
  OBJECT_KEYS,
  DOWNLOAD_TYPE,
  SKELETONS_NUMBER,
  SKELETON_VARIANT,
  PAGINATION,
  DEFAULT_SORTS,
  INFO,
  STATE,
} from "common/constants";
import { MAX_PARTICIPATION_TO_DOWNLOAD } from "common/constants/surveyProcess";
import {
  getDownloadTypes,
  getAverageResult,
  getIdParam,
  isAdmin,
  truncateString,
  isManager,
} from "common/utils";
import {
  getSurveyResults,
  getSurveyProcessParticipation,
  getSurveyResultsToDownload,
  resetStateSurveyResultsToDownload,
  sendParticipationToEmail,
  publishProcess,
} from "redux/actions/surveyProcessesActions";
import { getList as getPerformanceProcess } from "redux/actions/performance/performanceActions";
import {
  getTopList,
  getBottomList,
} from "redux/actions/performance/rankingActions";
import { getList as getHeatMapList } from "redux/actions/performance/heatMapActions";
import {
  StyledDashboardContainer,
  StyledPaperContainer,
  StyledGridContainer,
  StyledSubtitle,
} from "styledComponents/View";
import { StyledGridCards, StyledGridButton, StyledGridParticipation } from "./styles";
import Collaborators from "./components/Collaborators";
import Evaluators from "../Evaluators";
import {
  downloadHeatMapExcel,
  getCriticTableHeader,
  getHeatPicker,
  downloadHeatMapPDF,
  getTableTopsKeys,
  allCards,
} from "./functions";

// TODO: refactor and use new architecture
const Performance = () => {
  const { t, i18n } = useTranslation("performance", "common");
  const { language } = i18n;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(charts.breakpoints.small),
  );
  const { pagination, setPagination, handlePagination } = usePagination(1);
  const [dispatched, setDispatched] = useState(false);
  const [filterQuery, setFilterQuery] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingParticipation, setIsDownloadingParticipation] = useState(false);
  const [total, setTotal] = useState(0);
  const [values, setValues] = useState({
    evaluation: "",
    downloadHeatMap: DOWNLOAD_TYPE.none,
    initial: true,
  });
  const [selectHeatMap, setSelectHeatMap] = useState(ORG_UNITS.division);
  const [tableSort, setTableSort] = useState(DEFAULT_SORTS.score);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPending, setIsPending] = useState(null);
  const [heatMapHeaders, setHeatMapHeaders] = useState([]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const {
    state: { user },
  } = useContext(SessionContext);

  const isAdminUser = isAdmin(user?.userCookies);
  const isManagerUser = isManager(user?.userCookies);

  const prevSelecHeatMapRef = useRef();
  useEffect(() => {
    prevSelecHeatMapRef.current = selectHeatMap;
  });
  const prevSelecHeatMap = prevSelecHeatMapRef.current;

  const {
    performanceEvolution,
    loadingPerformanceEvolution,
    oneProcess: surveyProcess,
    isLoadingOneProcess: isLoadingSurveyProcess,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const {
    list: performanceProcessesList,
    loadingList: loadingPerformanceProcessesList,
  } = useSelector(({ performanceReducer }) => performanceReducer);

  const {
    topList,
    loadingTopList,
    bottomList,
    loadingBottomList,
  } = useSelector(({ performanceRankingReducer }) => performanceRankingReducer);

  const { list: heatMapList, isLoadingList: isLoadingHeatMapList } = useSelector(
    ({ performanceHeatMapReducer }) => performanceHeatMapReducer,
  );

  const {
    list: surveyResultList,
    listTotal: surveyResultTotal,
    isLoadingList: surveyResultLoadingList,
    downloadList,
    isLoadingDownloadList,
  } = useSelector(({ surveyResultReducer }) => surveyResultReducer);

  useEffect(() => {
    dispatch(getPerformanceProcess());
  }, [dispatch, values.evaluation]);

  useEffect(() => {
    if (values.evaluation) {
      if (prevSelecHeatMap !== selectHeatMap) {
        dispatch(getHeatMapList(values.evaluation, selectHeatMap, filterQuery));
      }
    }
  }, [
    dispatch,
    values.evaluation,
    selectHeatMap,
    prevSelecHeatMap,
    filterQuery,
  ]);

  useEffect(() => {
    setHeatMapHeaders(getCriticTableHeader(t, selectHeatMap, heatMapList));
  }, [heatMapList]);

  const getEvaluations = useCallback(
    (isChart, isFilter) => {
      const evaluations = [];
      const chartNames = [];
      if (!loadingPerformanceProcessesList && performanceProcessesList) {
        performanceProcessesList.forEach((item) => {
          if (item.has_results) {
            evaluations.push({
              value: item.id,
              label: item[`name_${language}`],
              followUp: item.follow_up_process_id,
            });
          }
        });
      }

      const evolutionIds = new Set(performanceEvolution?.map((item) => item.id) || []);

      evaluations.forEach((evaluation) => {
        if (evolutionIds.has(evaluation.value) && isNull(evaluation.followUp)) {
          chartNames.push(evaluation.label);
        }
      });

      if (isFilter) {
        return evaluations;
      }

      return isChart ? chartNames.reverse() : evaluations;
    },
    [
      loadingPerformanceProcessesList,
      performanceProcessesList,
      language,
      performanceEvolution,
    ],
  );

  const getInitialData = useCallback(
    (process) => {
      const evaluations = getEvaluations(false, true);
      const processId = getIdParam(location.search);
      /* If processId don't include in list then get the first element of the list */
      const evaluation = evaluations.find((item) => item.value === processId)?.value
        || evaluations[0]?.value;
      if (!isEmpty(evaluations) && process) {
        setValues(() => ({
          evaluation,
          initial: true,
        }));
      }
    },
    [getEvaluations, location.search],
  );

  useEffect(() => {
    if (performanceProcessesList && !values.evaluation) {
      getInitialData(performanceProcessesList);
    }
  }, [getInitialData, performanceProcessesList, values.evaluation]);

  const handleChange = (prop, event) => {
    const processId = event.target.value;
    if (prop === "selectHeatMap") {
      setSelectHeatMap(processId);
    } else {
      dispatch(getTopList(processId, filterQuery));
      dispatch(getBottomList(processId, filterQuery));
      dispatch(getHeatMapList(processId, selectHeatMap, filterQuery));
      dispatch(getSurveyResults(processId, false, 1, filterQuery));
      dispatch(getSurveyProcessParticipation(processId, filterQuery));
      setValues({ ...values, [prop]: processId });
    }
  };

  const handleDownloadHeatMap = (prop, event) => {
    const { value } = event.target;
    if (value !== DOWNLOAD_TYPE.none && value === DOWNLOAD_TYPE.excel) {
      downloadHeatMapExcel(heatMapList, t, surveyProcess.performance_process?.goals_active);
    } else {
      downloadHeatMapPDF(selectHeatMap);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsPending(null);
  };

  const handleOpen = () => {
    setIsOpen(true);
    setIsPending(true);
  };

  const onChangeOption = (value) => {
    setIsPending(value === 0);
  };

  const getParticipation = () => [
    {
      label: t("common:participation.pending"),
      value: 0,
      content: <Evaluators
        evaluation={ values.evaluation }
        filterQuery={ filterQuery }
        isPending={ isPending }
      />,
    },
    {
      label: t("common:participation.finished"),
      value: 1,
      content: <Evaluators
        evaluation={ values.evaluation }
        filterQuery={ filterQuery }
        isPending={ isPending }
      />,
    },
  ];

  useHandleDispatch(pagination, setDispatched);

  const handleSearch = (name = "") => {
    setSearchFilter(name);
  };

  useEffect(() => {
    if (filterQuery) {
      const query = filterQuery;
      query.q.employee_person_full_name_cont = searchFilter;
      query.q.s = tableSort;
      if (!surveyResultLoadingList && !isNull(surveyResultList) && !values.initial) {
        dispatch(
          getSurveyResults(values.evaluation, false, PAGINATION.next, query),
        );
        setPagination(PAGINATION.next);
      }
      setValues({ ...values, initial: false });
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [searchFilter, tableSort]);

  const handleExternalQuery = (query) => {
    const tempFilterQuery = query;
    dispatch(getSurveyProcessParticipation(values.evaluation, query));
    tempFilterQuery.q.s = tableSort;
    tempFilterQuery.q.employee_person_full_name_cont = searchFilter;
    setFilterQuery(tempFilterQuery);
  };

  const resetDispatched = (isReset) => {
    setDispatched(isReset);
  };

  const handleDownloadExcel = async () => {
    const processId = values.evaluation;
    setIsDownloading(true);
    setIsDownloadingParticipation(true);
    if (total > MAX_PARTICIPATION_TO_DOWNLOAD) {
      const sendParticipation = await dispatch(
        sendParticipationToEmail(processId, filterQuery),
      );
      if (sendParticipation) {
        handleOpenModal();
      }
      setIsDownloadingParticipation(false);
      setIsDownloading(false);
    } else {
      dispatch(
        getSurveyResultsToDownload(processId, filterQuery, surveyResultTotal),
      );
    }
  };

  useEffect(() => {
    if (!isLoadingDownloadList && downloadList && isDownloadingParticipation) {
      const id = values.evaluation;
      const evaluationsToShow = surveyProcess?.performance_process?.evaluations || [];
      downloadExcelFile(
        dispatch,
        id,
        filterQuery,
        t,
        setIsDownloading,
        evaluationsToShow,
        downloadList,
      );
      dispatch(resetStateSurveyResultsToDownload());
      setIsDownloadingParticipation(false);
    }
    // eslint-disable-next-line
  }, [downloadList]);

  useEffect(() => {
    const { meta } = surveyProcess || {};
    const maxParticipation = meta?.general_participation?.total_evaluators || 0;
    setTotal(maxParticipation);
  }, [surveyProcess]);

  // Calibration

  const [published, setPublished] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dialog, setDialog] = useState(false);

  const handlePublishProcess = async () => {
    const processId = values.evaluation;
    const publish = await dispatch(publishProcess(processId));
    if (publish?.message) {
      toast(MESSAGE_TYPES.success, { message: publish.message });
      setPublished(false);
      setIsLoading(false);
      setDialog(false);
    }
  };
  const performanceProcess = surveyProcess?.performance_process;
  const hasCalibrationActivated = performanceProcess?.calibration_activated
  && !performanceProcess?.published && published
  && performanceProcess?.state === STATE.finished;
  const resultScaleId = performanceProcess?.result_scale_id;

  const {
    one: resultScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  useEffect(() => {
    if (resultScaleId) {
      dispatch(getResultScaleById(resultScaleId));
    }
  }, [resultScaleId]);

  const alertInfo = <h4>{t(`surveys:calibration.publish.${performanceProcess?.closure_email ? "with_email" : "without_email"}`)}</h4>;

  const getCards = isLoadingSurveyProcess ? (
    <SkeletonLoader
      numberOfSkeletons={ SKELETONS_NUMBER.ONE }
      variant={ SKELETON_VARIANT.rectangular }
      height={ 150 }
      columnWidth={ isMobile ? 6 : 4 }
    />
  ) : (
    surveyProcess?.meta?.general_participation && surveyProcess?.performance_process?.name
    && allCards(surveyProcess.meta.general_participation).map((item, index) => (
      <Grid item xs={ 12 } sm={ 6 } md={ 4 } key={ `card-${index}` }>
        <IndividualAccordion
          title={ t("common:participation.title") }
          content={ (
            <ParticipationCard
              title={ t("common:participation.cardTitle") }
              participation={ item.participation }
              onClick={ handleOpen }
              isFullWidth
            />
          ) }
          isActive={ getEvaluations(false, true)[0]?.value === values.evaluation
            && !hasCalibrationActivated }
        />
        <Modal
          isOpen={ isOpen }
          onClose={ handleClose }
        >
          <StyledScrollBar maxHeight={ "600px" }>
            <ParticipationCard
              title={ `${t("common:common.participation")} ${truncateString(surveyProcess.performance_process.name)}` }
              participation={ item.participation }
              isFullWidth
              isModal
            />
            <StyledGridParticipation container>
              <Grid item xs={ 12 }>
                <SimpleAlert
                  type={ INFO }
                  message={ t("common:participation.alert.info") }
                />
              </Grid>
              <Grid item xs={ 12 }>
                <SwitchSelectorContent
                  options={ getParticipation() }
                  hasTitle={ false }
                  onChangeOption={ onChangeOption }
                />
              </Grid>
            </StyledGridParticipation>
            {isAdminUser && (
              <StyledGridButton item xs={ 12 } md={ 3 } isModal>
                <DownloadExcelButton
                  onClick={ handleDownloadExcel }
                  isDisabled={ isDownloading || surveyResultLoadingList
                    || !surveyProcess || total === 0 }
                  isDownloadApart={ total > MAX_PARTICIPATION_TO_DOWNLOAD }
                >
                  { t("common:common.downloadAction") }
                </DownloadExcelButton>
                <ModalInfo
                  title={ t("common:common.modal_messages.send_data.title") }
                  text={ t("common:common.modal_messages.send_data.text", { email: user.email }) }
                  buttonText={ t("common:common.modal_messages.send_data.button") }
                  isOpen={ isModalOpen }
                  onClose={ handleCloseModal }
                />
              </StyledGridButton>
            )}
          </StyledScrollBar>
        </Modal>
      </Grid>
    ))
  );

  return (
    <StyledDashboardContainer data-testid={ "performance-review-container" }>
      <StyledGridContainer container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 6 } md={ 8 } lg={ 9 }>
          <ViewHeaderTitle title={ t("dashboard.title") } />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
          <Select
            id={ OBJECT_KEYS.evaluation }
            label={ t("dashboard.filter_evaluation") }
            disabled={ isEmpty(performanceProcessesList)
              || loadingPerformanceProcessesList }
            menuItems={ loadingPerformanceProcessesList ? [] : getEvaluations(false, true) }
            value={ loadingPerformanceProcessesList ? "" : values.evaluation }
            onChange={ handleChange }
            className={ "custom-select" }
          />
        </Grid>
      </StyledGridContainer>
      <Grid container>
        {values.evaluation && (
          <>
            <Grid item xs={ 12 } md={ 9 }>
              <Filter
                type={ FILTER_ACTIONS_TYPES.performance }
                externalParams={ [
                  values.evaluation,
                  selectHeatMap,
                  false,
                  pagination,
                ] }
                pagination={ pagination }
                dispatched={ { value: dispatched, reset: resetDispatched } }
                handleExternalQuery={ handleExternalQuery }
                isLoading={ surveyResultLoadingList }
                isMultiple
              />
            </Grid>
            {isAdminUser && (
              <StyledGridButton item xs={ 12 } md={ 3 }>
                <DownloadExcelButton
                  onClick={ handleDownloadExcel }
                  isDisabled={ isDownloading || surveyResultLoadingList
                    || !surveyProcess || total === 0 }
                  isDownloadApart={ total > MAX_PARTICIPATION_TO_DOWNLOAD }
                >
                  { t("dashboard.download_participation") }
                </DownloadExcelButton>
                <ModalInfo
                  title={ t("common:common.modal_messages.send_data.title") }
                  text={ t("common:common.modal_messages.send_data.text", { email: user.email }) }
                  buttonText={ t("common:common.modal_messages.send_data.button") }
                  isOpen={ isModalOpen }
                  onClose={ handleCloseModal }
                />
              </StyledGridButton>
            )}
          </>
        )}
      </Grid>
      <StyledGridCards container spacing={ 2 }>
        {performanceProcessesList && performanceProcessesList.length > 0 && getCards}
      </StyledGridCards>
      {(isAdminUser || isManagerUser)
        && hasCalibrationActivated
        && (
          <Grid item xs={ 12 } mt={ 1 }>
            <ActionCard
              title={ t("surveys:calibration.alert.title") }
              text={ t("surveys:calibration.alert.text") }
              actionName={ t("surveys:calibration.alert.actions.publish") }
              onClick={ isAdminUser ? () => setDialog(true) : null }
              extraInfo={ !isAdminUser ? t("surveys:calibration.alert.info") : null }
            // secondaryAction={ {
            //   title: t("surveys:calibration.alert.actions.calibrate"),
            //   handleOnClick: () => {},
            // } }
            />
            <AlertDialog
              isOpen={ dialog }
              onClose={ () => setDialog(false) }
              title={ t("surveys:calibration.publish.title") }
              message={ t("surveys:calibration.publish.text") }
              additionalInfo={ alertInfo }
              buttons={ {
                agree: t("surveys:calibration.publish.send"),
                disagree: t("common:common.cancel"),
                isLoading,
              } }
              onSubmit={ handlePublishProcess }
            />
          </Grid>
        )}
      <StyledPaperContainer>
        <StyledGridContainer container spacing={ 4 }>
          <Grid item xs={ 12 } sm={ 6 }>
            <SimpleTable
              title={ t("dashboard.top_collaborators") }
              header={ getTableTopsKeys(history, values.evaluation) }
              hideHeader
              data={ topList }
              isLoading={ loadingTopList }
              colSpan={ 2 }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 }>
            <SimpleTable
              title={ t("dashboard.bottom_collaborators") }
              header={ getTableTopsKeys(history, values.evaluation) }
              hideHeader
              data={ bottomList }
              isLoading={ loadingBottomList }
              colSpan={ 2 }
            />
          </Grid>
        </StyledGridContainer>
        <Grid container spacing={ 4 }>
          <Grid item xs={ 12 }>
            <StyledSubtitle>{t("dashboard.graphic_title")}</StyledSubtitle>
          </Grid>
          <Grid item xs={ 12 }>
            <PerformanceChart
              title={ t("dashboard.graphic_title") }
              labels={ getEvaluations(true) }
              data={ performanceEvolution
                ? getAverageResult(performanceEvolution)
                : [] }
              isLoading={ loadingPerformanceEvolution }
            />
          </Grid>
        </Grid>
      </StyledPaperContainer>
      <StyledPaperContainer id={ "performanceHeatMap" }>
        <Grid container spacing={ 4 }>
          <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
            <StyledSubtitle>{t("dashboard.heat_map_title")}</StyledSubtitle>
          </Grid>
          <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
            <Select
              id={ "selectHeatMap" }
              label={ t("dashboard.select") }
              disabled={ isEmpty(heatMapList) }
              menuItems={ getHeatPicker(t) }
              value={ selectHeatMap }
              onChange={ handleChange }
              className={ "custom-select" }
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
            <Select
              id={ "downloadHeatMap" }
              label={ t("dashboard.download") }
              menuItems={ getDownloadTypes(t) }
              value={ values.downloadHeatMap }
              onChange={ handleDownloadHeatMap }
              className={ "custom-select" }
              disabled={ isEmpty(heatMapList) }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <CriticAreaTable
              header={ heatMapHeaders }
              data={ heatMapList }
              isDisabled={ isEmpty(heatMapList) }
              isLoading={ isLoadingHeatMapList }
              resultScale={ resultScale }
            />
          </Grid>
        </Grid>
      </StyledPaperContainer>
      {values.evaluation && (
        <StyledPaperContainer>
          <Collaborators
            processId={ values.evaluation }
            pagination={ pagination }
            handlePagination={ handlePagination }
            handleSearch={ handleSearch }
            query={ filterQuery }
            setTableSort={ setTableSort }
            isDownloadingParticipation={ isDownloadingParticipation }
            surveyProcess={ performanceProcess }
          />
        </StyledPaperContainer>
      )}
    </StyledDashboardContainer>
  );
};

export default Performance;
