import styled from "styled-components";
import palette, { STATE_COLORS } from "theme/palette";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Chip from "@mui/material/Chip";
import Button from "components/Button";

export const tabsContainerStyles = {
  marginBottom: "3px",
  ".MuiTab-root ": { textTransform: "none !important" },
};

export const mainTabsStyles = {
  minHeight: 38,
  ".MuiTabs-flexContainer": {
    justifyContent: "space-between",
    columnGap: "0.5%",
    borderBottom: `4px solid ${palette.background.mediumGrey}`,
  },
  "@media (max-width: 600px)": {
    ".MuiTabs-flexContainer": {
      flexDirection: "column",
      columnGap: "0",
      rowGap: "3%",
    },
  },
};

export const singleTabStyles = {
  width: "24.5%",
  maxWidth: "25%",
  minHeight: 38,
  justifyContent: "start",
  borderRadius: "4px 4px 0 0",
  color: palette.title.subtitleColor,
  backgroundColor: palette.background.greyMediumLight,
  "&.MuiTab-root": {
    padding: "8px 16px !important",
    fontWeight: "700 !important",
  },
  "&.Mui-selected": {
    color: `${palette.text.link} !important`,
    textDecoration: "underline",
  },
  "@media (max-width: 600px)": {
    width: "100%",
    maxWidth: "100%",
    "&.MuiTab-root": {
      margin: "8px auto",
      padding: "8px 8px !important",
    },
    "&.MuiTab-root:first-child": {
      marginTop: "0px",
    },
    "&.MuiTab-root:last-child": {
      marginBottom: "16px",
    },
  },
};

export const StyledAddButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
  width: 180px;
  height: 150px;
  background-color: ${palette.primaryApp};
  border: none;
  border-radius: 4px;
  color: ${palette.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin: 24px 0 24px 0;
  box-shadow: ${palette.boxShadow.card4};
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${palette.background.button};
  }

  &:focus {
    outline: none;
  }

  svg {
    width: 45px;
    height: 45px;
  }

  span {
    width: 75%;
  }
`;

export const StyledEditContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  row-gap: 8px;
  width: 180px;
  height: 150px;
  background-color: ${palette.white};
  margin: 20px 20px 0 0;
  border: none;
  border-radius: 4px;
  box-shadow: ${palette.boxShadow.card4};
  transition: all 0.2s ease-in-out;

  > div:first-child {
    position: absolute;
    top: 0;
    right: 0;
  }
  .draft {
    width: 75%;
    font-size: 14px;
    font-weight: 700;
  }
  p {
    width: 75%;
    font-size: 12px;
    font-weight: 500;
  }
  button{
    min-width: 0;
  }
`;

export const StyledText = styled.div`
  color: ${ STATE_COLORS.ERROR };
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${ STATE_COLORS.ERROR };
`;

export const StyledEditIcon = styled(EditOutlined)`
  color: ${palette.table.blue};
`;

export const StyledEditText = styled.div`
  color: ${palette.table.blue};
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;
`;

export const StyledSubtitle = styled.div`
  h6 {
    font-size: 12px;
    line-height: 16px;
    margin: 5px 0 10px;
  }
`;

export const StyledChip = styled(Chip)`
  font-weight: 500;
  font-size: 11px;
  background-color: ${(props) => (props.backgroundColor === "primary" ? palette.primaryApp : palette.background.lightGrey)};
  color: ${(props) => (props.backgroundColor === "primary" ? palette.white : palette.black)};
  align-self: ${(props) => props.align && "end"};
  margin-right: ${(props) => props.align && "10px"};

`;
