import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  replacePath,
  getItemFromLocalStorage,
  getValueByProp,
  getCurrencyFormat,
} from "common/utils";
import { isEqual } from "common/helpers";
import {
  FORM_MODE, LOCAL_STORAGE_NAMES, MIN_VALUE, OBJECT_KEYS,
} from "common/constants";
import useComplexState from "hooks/utils/useComplexState";
import localRoutes from "../functions/routes";
import {
  dispatchUpdate,
  getSelectedModal,
  resetDefaultSalaryValues,
} from "../functions/profile";

export const useSalaryInfoHook = (externalHooks, internalHooks) => {
  const {
    dispatch, location,
  } = externalHooks;
  const {
    collaborator,
    isAdmin,
    isManager,
  } = internalHooks;

  const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);
  const [salaryInfoStates, setSalaryInfoStates] = useComplexState({
    isModalOpen: {
      salaryInfo: false,
    },
    isAdminOrManager: isAdmin || isManager,
    codeCurrency: "",
    contractEmploymentRelationship: null,
    projectedCtc: 0,
    exchange: 0,
    optionsList: {
      currencies,
    },
  });

  const employee = "collaborator"; // TODO: change this to dynamic when candidate view is ready

  const {
    control, handleSubmit, reset, errors,
  } = useForm({
    defaultValues: resetDefaultSalaryValues(employee),
    mode: FORM_MODE.onChange,
  });

  // use effect need it to handle the routes (for modals)
  useEffect(() => {
    if (!isEqual(location.pathname, localRoutes.root)) {
      getSelectedModal(location.pathname).then((resolve) => {
        const { path, key } = resolve;
        replacePath(path);
        setSalaryInfoStates({
          isModalOpen: {
            ...salaryInfoStates.isModalOpen,
            [key]: true,
          },
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (collaborator) {
      reset(resetDefaultSalaryValues(employee, collaborator));
      const contractEmploymentRelationship = collaborator.job_position?.type_of_contract?.employment_relationship_id;
      const codeCurrency = currencies.find(
        (currency) => currency.name_with_code === collaborator?.salary?.currency
        || currency.code === collaborator?.salary?.currency,
      )?.code;
      setSalaryInfoStates({
        codeCurrency,
        projectedCtc: contractEmploymentRelationship || collaborator?.salary?.projected_ctc ? getCurrencyFormat(collaborator?.salary?.projected_ctc) : MIN_VALUE,
        exchange: contractEmploymentRelationship || collaborator?.salary?.projected_ctc ? getCurrencyFormat(
          collaborator?.salary?.projected_ctc,
          collaborator?.salary?.currency,
          currencies,
        ) : MIN_VALUE,
      });
    }
    // eslint-disable-next-line
	}, [collaborator]);

  const onSubmit = (data) => {
    data[employee].salary_attributes.gross_salary = data[employee].salary_attributes.gross_salary.replaceAll(",", "");
    data[employee].salary_attributes.currency = getValueByProp(data[employee].salary_attributes.currency, OBJECT_KEYS.nameWithCode);
    dispatchUpdate(dispatch, employee, data, collaborator.id);
  };

  return {
    salaryInfoStates,
    hookSalaryForm: {
      control,
      handleSubmit,
      reset,
    },
    salaryErrors: errors,
    onSalarySubmit: onSubmit,
  };
};
