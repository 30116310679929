import { useQuery, useMutation, useQueryClient } from "react-query";
import { getNineBoxTypes, updateNineBoxType } from "redux/actions/talentScore/talentScoreActions";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import { handleGenericError } from "common/handleGenericError";

const handleQueryError = (error, t) => {
  const { title, message, type } = handleGenericError(error, t);
  toast(type, { title, message });
};

export const useNineBoxTypes = (t) => {
  const {
    data: nineBoxTypeList,
    isLoading,
    isError,
    error,
  } = useQuery("nineBoxTypeList", getNineBoxTypes, {
    staleTime: Infinity,
    onError: (queryError) => handleQueryError(queryError, t),
  });

  return {
    nineBoxTypeList,
    isLoading,
    isError,
    error,
  };
};

export const useUpdateNineBoxType = (t) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ id, data }) => updateNineBoxType({ id, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("nineBoxTypeList");
        toast(MESSAGE_TYPES.success, { message: t("common:nine_box_type.alerts.update") });
      },
      onError: (mutationError) => handleQueryError(mutationError, t),
    },
  );

  return mutation;
};
